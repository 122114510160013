import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['ButtonDialog_labelEmotions'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextCustom = makeShortcode("TextCustom");
const ButtonDialog = makeShortcode("ButtonDialog");
const Img = makeShortcode("Img");
const Grid = makeShortcode("Grid");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "identify-validate--understand"
    }}>{`Identify, validate & understand`}</h1>
    <TextCustom customColor="blue" variant="h3" mdxType="TextCustom">
  Identify
    </TextCustom>
    <p><strong parentName="p">{`Identifying and labelling the emotion helps your teenager feel heard`}</strong>{` and it helps you understand their world better. If you see that your teenager is feeling a strong emotion, ask about their feelings, e.g. "You look worried" or "It sounds like you're really angry".`}</p>
    <ButtonDialog linkText="Suggestions for labelling Emotions" id="labelEmotions" mdxType="ButtonDialog">
  <Img src="/images/m1/feelingsHero.png" mdxType="Img" />
    </ButtonDialog>
    <Grid container spacing={5} alignItems="center" mdxType="Grid">
  <Grid item xs={12} md={6} mdxType="Grid">
    <TextCustom customColor="maroon" variant="h3" mdxType="TextCustom">
      Validate
    </TextCustom>
    <p>
      <b>Acknowledge and show empathy for what your teenager is going through.</b> Teenagers often feel like no one
      understands them or has ever experienced what they are going through. Hearing "I've been there, done that" can
      often leave a teenager feeling dismissed. Validating the feelings your teenager is telling you in conversation
      will help them feel understood. Try "That must be really tough" or "What a tricky situation." This helps to show
      empathy because it communicates your concern for their feelings.
    </p>
    <TextCustom customColor="deepPurple" variant="h3" mdxType="TextCustom">
      Understand
    </TextCustom>
    <p>
      <b>Explore what has led to the emotions.</b> Ask your teenager about what has happened to make them feel this way.
      You could gently ask: "Is there something on your mind?", "What's making you so upset?" or "Would you like to talk
      about what's just happened?"
    </p>
    <p>
      Letting your teenager tell you what's going on helps them understand what they are feeling and to manage their
      emotions effectively. Giving your teenager time to talk will be important.
    </p>
  </Grid>
  <Grid item xs={12} md={6} mdxType="Grid">
    <Img src="/images/m1/4.svg" alt="Talking Example" mdxType="Img" />
  </Grid>
    </Grid>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      